import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import {
  setNotifications,
  setIsLoadingNotifications,
  setNotificationsError,
} from 'sharedComponents/notificationStoreSlice';
import { routes, withUserRoutes } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser, setAuthUser } from 'modules/auth/storeSliceAuth';
import 'rsuite/lib/styles/index.less';
import { getLoginDefaultContinueUrl } from 'modules/auth/logicDependentOnUserRole';
import { useLocation } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { ContextProvider, PermissionContextSynchronization } from 'services/permissions';
import ErrorsView from 'modules/errors/ErrorsView';
import SuccessesView from 'modules/successMessage/SuccessesView';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import { FullViewWidgetInitialize, FullViewWidgetSetUser } from 'sharedComponents/FullviewWidget';
import ErrorHandler from 'sharedComponents/ErrorHandler';
import InternetConnectionAlert from 'sharedComponents/InternetConnectionAlert';
import ReactGA from 'react-ga';
import { auth } from 'services/firebase';
import ReportBug from 'sharedComponents/ReportBug';

// todo: determine if this is the best to handle colors
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { MenuProvider } from 'modules/civilAndTrucking/shared/nav/SideBar/menuContext';
import { ErrorBoundary } from 'react-error-boundary';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

import api from 'services/api/autogenerated';

const GA_TRACKING_ID = 'G-R7570LY1YR';

ReactGA.initialize(GA_TRACKING_ID);

const query = new Query();
const executer = new Executer();

// This method is used to inject custom logic to javascript's console.error
const originalConsoleError = console.error;
console.error = (...args: any[]) => {
  const serializedArgs = args.map(arg => {
    if (arg instanceof Error) {
      return arg.stack;
    } else if (arg instanceof Object) {
      return JSON.stringify(arg);
    }
    return arg;
  });

  const formattedErrorMessage = `Error: ${serializedArgs}`;

  // Send error message to new relic
  newrelic.noticeError(new Error(formattedErrorMessage));

  originalConsoleError(`Error: ${serializedArgs}`);
};

const getAuthUserHashForEmulation = (user: { [key: string]: any } = {}) =>
  [user.id, user.actingAsType, (user.companiesIds || []).join('-')].join('_');

const App = () => {
  const routing = useRoutes(routes);
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authUser) {
      return;
    }

    // todo: DO NOT REMOVE - for debugging purposes
    console.log('u:', authUser);

    if (
      location.pathname === '/' ||
      location.pathname === '/login' ||
      (location.pathname === '/license-agreement' &&
        authUser.licenseAgreement &&
        authUser.licenseAgreement.isAccepted)
    ) {
      let url = new URL(window.location.href);
      const continueUrl = url.searchParams.get('continueUrl');

      if (!continueUrl) navigate(getLoginDefaultContinueUrl(authUser), { replace: true });
    }
  }, [authUser, location.pathname, navigate]);

  useEffect(() => {
    if (authUser === false) {
      return;
    }

    let url = new URL(window.location.href);
    const continueUrl = url.searchParams.get('continueUrl');
    const passwordInitial = url.searchParams.get('passwordInitial');
    const userPhone = url.searchParams.get('userPhone');
    if (authUser === null) {
      const pathParts = location.pathname.split('/');
      const isStandAlone = pathParts.indexOf('manifest-standalone') !== -1;

      if (location.pathname === '/custom') {
        return;
      }

      // TODO: Auth0: revert this
      // if (location.pathname === '/auth0-callback') {
      //   return;
      // }

      if (isStandAlone) {
        return;
      }

      if (continueUrl) {
        navigate(`/login?continueUrl=${encodeURIComponent(continueUrl)}`);
      } else if (!passwordInitial && !userPhone) {
        navigate('/login');
      }
      return;
    }

    if (location.pathname === '/app-download') {
      return;
    }

    // TODO: we should handle signature, firstName and lastName here (scale and region too)
    // I think we can render 'Account' view

    if (
      authUser.passwordInitial &&
      authUser.preferredLoginMethod !== 'phone' &&
      !authUser.auth0Id
    ) {
      if (continueUrl) {
        navigate(`/set-password?continueUrl=${encodeURIComponent(continueUrl)}`);
      } else {
        navigate('/set-password');
      }
      return;
    }

    if (!authUser.licenseAgreement || !authUser.licenseAgreement.isAccepted) {
      if (continueUrl) {
        navigate(`/license-agreement?continueUrl=${encodeURIComponent(continueUrl)}`);
      } else {
        navigate('/license-agreement');
      }
      return;
    }

    if (authUser && continueUrl) {
      navigate(continueUrl);
      return;
    }
    // eslint-disable-next-line
  }, [authUser]);

  useEffect(() => {
    let unsubscribeUser: Function | null = null;

    const unsubscribeAuth = auth.onAuthStateChanged(async (authUserResponse: any) => {
      if (!authUserResponse) {
        dispatch(setAuthUser(null));
      } else {
        unsubscribeUser = executer.watchSingleDocument(
          query.base.getById(QueryBase.USERS_COLLECTION(), authUserResponse.uid),
          userResponse => dispatch(setAuthUser(userResponse)),
          error => {
            console.error('Error getting document:', error);
            dispatch(setAuthUser(null));
          }
        );
      }
    });

    return () => {
      if (unsubscribeUser) unsubscribeUser();
      unsubscribeAuth();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    const NOTIFICATIONS_LIMIT = 10;
    const notificationQueries = query.projects.getNotificationQueries(
      authUser,
      NOTIFICATIONS_LIMIT
    );

    const unsubs = executer.watchMultipleDocumentsOnMultipleQueries(
      notificationQueries,
      (notifs: any) => {
        dispatch(
          setNotifications({
            notifications: notifs,
            limit: NOTIFICATIONS_LIMIT,
          })
        );
        dispatch(setIsLoadingNotifications(false));
        dispatch(setNotificationsError(''));
      },
      (err: Error) => {
        console.error('Error fetching notifications', err);
        dispatch(setIsLoadingNotifications(false));
        dispatch(setNotificationsError(`Error loading notifications`));
      }
    );

    return () => {
      if (Array.isArray(unsubs)) unsubs.forEach(unsub => unsub());
    };
  }, [dispatch, authUser]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    const loadHubSpotToken = async () => {
      const hubspotToken = await fetchHubspotVisitorToken(
        authUser.email,
        authUser.firstName,
        authUser.lastName
      );

      if (hubspotToken) {
        if (typeof window !== 'undefined') {
          // Poll for HubSpot Conversations to be ready
          const waitForHubSpot = setInterval(() => {
            if ((window as any).HubSpotConversations) {
              console.log('HubSpot chat widget is ready. Updating...');

              // Clear the interval and update the chat
              clearInterval(waitForHubSpot);
              (window as any).updateHubSpotChat(hubspotToken, authUser.email);
            } else {
              console.log('HubSpot chat is not ready yet.');
            }
          }, 500); // Check every 500ms
        }
      }
    };

    loadHubSpotToken();
  }, [authUser]);

  async function fetchHubspotVisitorToken(email: string, firstName: string, lastName: string) {
    const response = await api.hubSpot.getHubSpotToken({ email, firstName, lastName });
    return response.data.token;
  }

  return (
    <ThemeProvider theme={theme}>
      <ReportBug authUser={authUser} />
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <ConfirmProvider>
          {authUser === false ? null : authUser === null ? (
            routing
          ) : (
            <>
              <AppWithUser key={getAuthUserHashForEmulation(authUser)} />
              <FullViewWidgetInitialize key={`${getAuthUserHashForEmulation(authUser)}_fullview`} />
            </>
          )}
        </ConfirmProvider>
        <ErrorsView />
        <SuccessesView />
        <InternetConnectionAlert />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const AppWithUser = () => {
  const routing = useRoutes(withUserRoutes);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ContextProvider>
      <PermissionContextSynchronization>
        {() => (
          <>
            <MenuProvider>{routing!}</MenuProvider>
            <FullViewWidgetSetUser />
          </>
        )}
      </PermissionContextSynchronization>
    </ContextProvider>
  );
};

export default App;
